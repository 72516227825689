import axios from "axios";
import store from "redux/store";

const http = axios.create({
    baseURL: process.env.REACT_APP_BASEURL,
    headers: {
        "Accept": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-type": "application/json",
    },
    withCredentials: true,
});

http.interceptors.request.use( (req) => {
    if (localStorage.getItem("usrtkn")) {
        req.headers.Authorization = `Bearer ${localStorage.getItem("usrtkn")}`;
    }

    if (JSON.parse(localStorage.getItem("lang"))) {
        const currentLanguage = JSON.parse(localStorage.getItem("lang")).includes('it')
            ? "it-IT"
            : "en-US"
        req.headers["Accept-Language"] = currentLanguage;
    }
    return req;
});

http.interceptors.response.use( (res) => {
    const isNotNullableCompanyRoute = window.location.pathname !== '/' && window.location.pathname !== '/select-company' && window.location.pathname !== '/password-recovery' && window.location.pathname !== '/password-change';
   
    const token = store.getState()?.user?.token;
  
    if (!token && window.location.href.includes('orders') && window.location.href.includes('companyChannel')) {
        const url= new URL(window.location.href);
        const parameterMap = new Map(url.searchParams);
        const showNotes = parameterMap.get('showNotes');
        const showOrderTag = parameterMap.get('showOrderTag');
        const companyChannel = parameterMap.get('companyChannel');
        sessionStorage.setItem('URL_NOTES', JSON.stringify(window.location.pathname));
        sessionStorage.setItem('SHOW_NOTES', JSON.stringify(!!showNotes));
        sessionStorage.setItem('SHOW_ORDER_TAG', JSON.stringify(!!showOrderTag));
        sessionStorage.setItem('CHANNEL', JSON.stringify(companyChannel));
    }

    if (isNotNullableCompanyRoute && (res?.data?.selectedCompany === null || res?.data?.selectedCompanyChannel === null)) {
        localStorage.removeItem('usrtkn');
        window.location.replace("/");
        return false;
    }
    return res;
},
(error) => {
    if ( error.response?.status === 401 ) {
        localStorage.clear();
        window.location.replace("/");
    }
    const isNotNullableCompanyRoute = window.location.pathname !== '/' && window.location.pathname !== '/select-company' && window.location.pathname !== '/password-recovery' && window.location.pathname !== '/password-change';

    const token = store.getState()?.user?.token;

    if (!token && window.location.href.includes('orders') && window.location.href.includes('companyChannel')) {
        const url= new URL(window.location.href);
        const parameterMap = new Map(url.searchParams);
        const showNotes = parameterMap.get('showNotes');
        const showOrderTag = parameterMap.get('showOrderTag');
        const companyChannel = parameterMap.get('companyChannel');
        sessionStorage.setItem('URL_NOTES', JSON.stringify(window.location.pathname));
        sessionStorage.setItem('SHOW_NOTES', JSON.stringify(!!showNotes));
        sessionStorage.setItem('SHOW_ORDER_TAG', JSON.stringify(!!showOrderTag));
        sessionStorage.setItem('CHANNEL', JSON.stringify(companyChannel));
    }

    if (isNotNullableCompanyRoute && (error.response.data?.selectedCompany === null || error.response.data?.selectedCompanyChannel === null)) {
        localStorage.removeItem('usrtkn');
        window.location.replace("/");
        return false;
    }
    return Promise.reject(error);
})

export default http;
