import React from "react";
import PropTypes from "prop-types";
import { Notification as Toast } from "@progress/kendo-react-notification";
import alertIcon from "assets/icons/alert.svg";
import successIcon from "assets/icons/success.svg";
import "./notification.scss";

const Notification = (props) => {
  const { message, type: style } = props;

  let messageToShow = typeof message !== "string" ? message.username : message;

  const icon = style === "error" ? alertIcon : successIcon;

  return (
    <Toast
      type={{
        style,
        icon: false,
      }}
      closable={false}
    >
      <img src={icon} width={24} height={24} alt={`icon ${icon}`} />
      <span>{messageToShow}</span>
    </Toast>
  );
};

Notification.propTypes = {
  message: PropTypes.any,
  type: PropTypes.oneOf(["error", "success"]),
};

Notification.defaultProps = {
  message: "It is a success!",
  type: "success",
};

export default Notification;
