import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { Button } from "@progress/kendo-react-buttons";
import { Input } from "@progress/kendo-react-inputs";
import { forgotPassword } from "redux/features/user/actions";
import Loader from "components/loader";
import { toast } from "react-toastify";
import "./formPasswordRecovery.scss";
import { useCustomLocalization } from "utils";

import defaultMessages from 'i18n/en.json';

const useLocalizedMessages = () => {
  const [toLanguageString] = useCustomLocalization();
  return {
    buttonLabel: toLanguageString(
      'metislab.frontend.components.passwordRecovery.components.buttonLabel',
      defaultMessages.metislab.frontend.components.passwordRecovery.components.buttonLabel),
    goto: toLanguageString(
      'metislab.frontend.components.passwordRecovery.components.goto',
      defaultMessages.metislab.frontend.components.passwordRecovery.components.goto),  
    inputLabel: toLanguageString(
      'metislab.frontend.components.passwordRecovery.components.inputLabel',
      defaultMessages.metislab.frontend.components.passwordRecovery.components.inputLabel),
    inputPlaceholder: toLanguageString(
      'metislab.frontend.components.passwordRecovery.components.inputPlaceholder',
      defaultMessages.metislab.frontend.components.passwordRecovery.components.inputPlaceholder),
    lead: toLanguageString(
      'metislab.frontend.components.passwordRecovery.components.lead',
      defaultMessages.metislab.frontend.components.passwordRecovery.components.lead),  
    title: toLanguageString(
      'metislab.frontend.components.passwordRecovery.components.title',
      defaultMessages.metislab.frontend.components.passwordRecovery.components.title),
   
    sendEmailSuccess: toLanguageString(
      'metislab.frontend.components.notification.components.passwordRecovery.sendEmailSuccess',
      defaultMessages.metislab.frontend.components.notification.components.passwordRecovery.sendEmailSuccess),    
  };
}

const initialValues = { username: "" };

const recoverySchema = Yup.object().shape({
  username: Yup.string().required("required"),
});

const FormPasswordRecovery = () => {
  const {
    buttonLabel,
    goto,
    inputLabel,
    inputPlaceholder,
    lead,
    title,
    sendEmailSuccess
  } = useLocalizedMessages();

  const dispatch = useDispatch();
  const { loading } = useSelector((state) => ({ ...state.user }));

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => {
        const args = {
          toast, 
          values,
          messageNotification: sendEmailSuccess
        }
        dispatch(forgotPassword(args));
      }}
      validationSchema={recoverySchema}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
        } = props;

        return (
          <form className="c-form c-form--login" onSubmit={handleSubmit}>
            <div className="c-fieldset">
              <header className="c-fieldset__header">
                <h1 className="c-fieldset__title t-title t-title--1">
                  {title}
                </h1>
                <p>
                  {lead}
                </p>
              </header>
              <div className="c-fieldset__main">
                <div className="c-form-field">
                  <label htmlFor="email">{inputLabel}</label>
                  <Input
                    type="text"
                    name="username"
                    placeholder={inputPlaceholder}
                    value={values.username}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      errors.email && touched.email
                        ? "text-input error"
                        : "text-input"
                    }
                  />
                  {errors.email && touched.email && (
                    <div className="input-feedback">{errors.email}</div>
                  )}
                </div>
              </div>
              <footer className="c-fieldset__footer">
                <Button
                  icon={loading ? null : "arrow-chevron-right"}
                  dir="rtl"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? <Loader /> : buttonLabel}
                </Button>

                <Link to="/" className="c-back">
                  <svg
                    width={14}
                    height={12}
                    viewBox="0 0 14 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M2.89611 5.33342H13.6727V6.66675H2.88033L6.61997 10.4064L5.67716 11.3492L0.335938 6.00797L5.67716 0.666748L6.61997 1.60956L2.89611 5.33342Z"
                      fill="#2A2A2A"
                    />
                  </svg>
                  {goto}
                </Link>
              </footer>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default FormPasswordRecovery;
