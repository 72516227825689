import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Button } from "@progress/kendo-react-buttons";
import { ScrollView } from "@progress/kendo-react-scrollview";
import { Skeleton } from "@progress/kendo-react-indicators";
import ArticleGallery from "pages/catalog/product/articleGallery";
import "./carousel.scss";

const Carousel = (props) => {
  const {
    automaticViewChange = false,
    automaticViewChangeInterval,
    arrows = false,
    height = 520,
    images = [],
    loader = false,
    pageable = false,
    slides = true,
    templates = [],
    width = 520,
    actionOnMainImage = false,
    fragranceId = null,
  } = props;

  const refCarousel = useRef();

  const [activeSlide, setactiveSlide] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (!loader && images?.length > 0) {
      if (activeSlide) {
        const scrollviewWrap = refCarousel.current.element?.children[0];
        scrollviewWrap &&
          scrollviewWrap.style.setProperty(
            "--kendo-scrollview-current",
            activeSlide
          );
      } else {
        setactiveSlide(1);
      }
    }
  }, [activeSlide, images?.length, loader]);

  const handleClick = useCallback( () => {
    if (actionOnMainImage) {
      setShowModal( (prevState) => !prevState);
    }
  }, [actionOnMainImage])

  /**
   * Slides create the sequence of images below the slider
   * @returns {Slides} component
   */
  const Slides = () => {
    return images.map((image, index) => (
      <li key={index}>
        <Button
          className={activeSlide === index + 1 && "j-active"}
          fillMode="flat"
          onClick={() => setactiveSlide(index + 1)}
        >
          <img src={image} alt="slide" />
        </Button>
      </li>
    ));
  };

  if (loader) {
    return (
      <div className="c-slider k-relative">
        <Skeleton
          className="c-slider__loader-background"
          shape="rectangle"
          style={{
            display: "block",
            width: "100%",
            height: "100%"
          }}
        />
        <div className="c-slider__content">
          <Skeleton
            className="c-slider__title t-title"
            style={{
              width: 160,
           }}
          />
          <Skeleton
            className="c-slider__text"
            style={{
              width: 240,
            }}
          />
          <Skeleton
            style={{
              width: 120,
              height: 40
            }}
          />
        </div>
      </div>
    )
  }

  return (
    <>
    <div className="c-slider-container u-flex-1">
      {images?.length > 0 ? (
        <>
          <ScrollView
            className="c-slider"
            ref={refCarousel}
            arrows={arrows}
            automaticViewChange={automaticViewChange}
            automaticViewChangeInterval={automaticViewChangeInterval}
            endless={automaticViewChange}
            pageable={pageable}
            style={{
              width,
              height,
            }}
          >
            {images.map((image, index) => {
              return (
                <div className="c-slider__slide" key={index}>
                  {templates?.length > 0 && (
                    <div className="c-slider__content">
                      {templates[index] && (
                        <h1 className="c-slider__title t-title" dangerouslySetInnerHTML={{__html: templates[index]?.title}}></h1>
                      )}
                      {templates[index] && (
                        <p className="c-slider__text" dangerouslySetInnerHTML={{__html: templates[index]?.text}}></p>
                      )}
                      {templates[index] && (
                        <Link
                          className="c-slider__button k-button k-button-md"
                          to={templates[index]?.button_link}
                        >
                          {templates[index]?.button_text}
                        </Link>
                      )}
                    </div>
                  )}
                  <div
                    role="button"
                    className="c-slider__button-gallery"
                    onClick={handleClick}
                    style={{
                      cursor: actionOnMainImage ? 'pointer' : 'default',
                    }}
                  >
                    <figure className="c-slider__figure" key={index}>
                      <img
                        src={image}
                        alt="slide"
                        style={{
                          width,
                          height,
                        }}
                      />
                    </figure>
                  </div>
                </div>
              );
            })}
          </ScrollView>
          {slides && (
            <ul className="c-slider__nav l-mobile-flex">
              <Slides />
            </ul>
          )}
        </>
      ) : (
        <figure className="c-placeholder">
          <img src="/img/placeholder-big.jpg" alt="slide" />
        </figure>
      )}
    </div>
    {showModal && (
      <ArticleGallery articleId={fragranceId} handleGallery={handleClick} />
    )}
    </>
  );
};

Carousel.propTypes = {
  automaticViewChange: PropTypes.bool,
  automaticViewChangeInterval: PropTypes.number,
  arrows: PropTypes.bool,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  images: PropTypes.array,
  pageable: PropTypes.bool,
  slides: PropTypes.bool,
  templates: PropTypes.array,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  actionOnMainImage: PropTypes.bool,
  fragranceId: PropTypes.number,
};

export default Carousel;
