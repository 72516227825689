import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getOrdersProcessing } from "redux/features/order/actions";
import { setPagerStateOrderProcessing } from "redux/features/order";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import CellApprover from "./cellApprover";
import CellCustomerDescription from "./cellCustomerDescription";
import CellDateCreation from "./cellDateCreation";
import CellDetails from "./cellDetails";
import CellIDOrder from "./cellIDOrder";
import CellState from "./cellState";
import CellTotal from "./cellTotal";
import HeaderOrderListing from "./header";
import ModalDocumentsOrder from "./modalDocumentsOrder";
import EmptySearch from "components/emptySearch";
import Loader from "components/loader";
import Notification from "components/notification";
import { toast } from "react-toastify";
import "./orders-pending.scss";
import { useCustomLocalization } from "utils";

import defaultMessages from "i18n/en.json";

const useLocalizedMessages = () => {
  const [toLanguageString] = useCustomLocalization();
  return {
    labelEmptyListOrder: toLanguageString(
      "metislab.frontend.components.orders.components.emptyListOrder.label",
      defaultMessages.metislab.frontend.components.orders.components
        .emptyListOrder.label
    ),
    labelStrongEmptyListOrder: toLanguageString(
      "metislab.frontend.components.orders.components.emptyListOrder.labelStrong",
      defaultMessages.metislab.frontend.components.orders.components
        .emptyListOrder.labelStrong
    ),
    labelEmptySearch: toLanguageString(
      "metislab.frontend.components.orders.components.emptySearch.label",
      defaultMessages.metislab.frontend.components.orders.components.emptySearch
        .label
    ),
    labelStrongEmptySearch: toLanguageString(
      "metislab.frontend.components.orders.components.emptySearch.labelStrong",
      defaultMessages.metislab.frontend.components.orders.components.emptySearch
        .labelStrong
    ),
  };
};

const OrdersProcessing = () => {
  const {
    labelEmptyListOrder,
    labelStrongEmptyListOrder,
    labelEmptySearch,
    labelStrongEmptySearch,
  } = useLocalizedMessages();

  const dispatch = useDispatch();

  /**
   * Order states
   */
  const errorOrder = useSelector((state) => state.order.error);
  const orders = useSelector((state) => state.order.orders);
  const layoutColumn = useSelector((state) => state.order.layoutColumn);
  const loading = useSelector((state) => state.order.loadingProcessing);
  const pagerState = useSelector(
    (state) => state.order.pagerStateOrderProcessing
  );
  const pagination = useSelector((state) => state.order.pagination);
  const pageSizes = useSelector((state) => state.order.pageSizes);

  /**
   * IndexWebCustomer states
   */
  const companyCustomers = useSelector(
    (state) => state.indexWebCustomer.indexCustomers
  );
  const loadingIndexWebCustomer = useSelector(
    (state) => state.indexWebCustomer.loading
  );
  const selectedCompany = useSelector(
    (state) => state.indexWebCustomer.selectedCompany
  );

  /**
   * User states
   */
  const loadingOrder = useSelector((state) => state.user.loading);

  /**
   * Internal states
   */
  const [showModal, setShowModal] = useState(false);

  /**
   * getListOfOrders is a callback's hook
   * used to get the list of orders
   */
  const getListOfOrders = useCallback(() => {
    if (selectedCompany && companyCustomers) {
      dispatch(getOrdersProcessing({ pager: pagerState, toast }));
    }
  }, [companyCustomers, dispatch, pagerState, selectedCompany]);

  useEffect(() => {
    getListOfOrders();
  }, [getListOfOrders]);

  /**
   * pageChange is the function to handle the change between pages
   * @param {object} e - It's an event object
   */
  const pageChange = (e) => {
    const { skip, take } = e.page;
    dispatch(
      setPagerStateOrderProcessing({
        page: skip / take + 1,
        perPage: take,
      })
    );
  };

  /**
   * sortChange is the function to handle the sort of customers by column
   * @param {object} e - It's an event object
   */
  const sortChange = (e) => {
    if (e.sort.length) {
      const { field, dir } = e.sort[0];
      dispatch(
        setPagerStateOrderProcessing({
          orderBy: field,
          orderByDirection: dir,
        })
      );
    } else {
      dispatch(
        setPagerStateOrderProcessing({
          orderBy: null,
          orderByDirection: null,
        })
      );
    }
  };

  const handleModal = () => {
    setShowModal((prevState) => !prevState);
  };

  /**
   * Render the cell in column customer description
   * @param {object} data
   * @returns {cellCustomerDescription} component
   */
  const cellCustomerDescription = (data) => {
    const { customer_description, fido, search_code } = data.dataItem;
    return (
      <CellCustomerDescription
        customerDescription={customer_description}
        customerCode={search_code}
        fido={fido}
      />
    );
  };

  /**
   * Render the cell in column id order
   * @param {object} data
   * @returns {cellIDOrder} component
   */
  const cellIDOrder = (data) => {
    const { code, grid_tooltip } = data.dataItem;
    return <CellIDOrder code={code} gridTooltip={grid_tooltip} />;
  };

  /**
   * Render the cell in column date
   * @param {object} data
   * @returns {cellDateCreation} component
   */
  const cellDateCreation = (data) => {
    const { creation_date } = data.dataItem;
    return <CellDateCreation date={creation_date} />;
  };

  /**
   * Render the cell in column date delivery
   * @param {object} data
   * @returns {cellDateDelivery} component
   */
  const cellDateDelivery = (data) => {
    const { delivery_date } = data.dataItem;
    return <CellDateCreation date={delivery_date} isDateDelivery={true} />;
  };

  /**
   * Render the cell in column state
   * @param {object} data
   * @returns {CellAgent} component
   */
  const cellState = (data) => {
    const states = layoutColumn.find((column) => column.propId === "state")
      .editor.data.rows;
    const stateLabel = states.find(
      (state) => Number(state.value) === data?.dataItem?.state
    );

    return (
      <CellState
        stateLabel={stateLabel?.label}
        state={data?.dataItem?.state}
        note={data?.dataItem?.note}
      />
    );
  };

  /**
   * Render the cell in column details
   * @param {object} data
   * @returns {CellDetails} component
   */
  const cellDetails = (data) => {
    const { code, id } = data.dataItem;
    return <CellDetails id={id} idOrder={code} handleModal={handleModal} />;
  };

  /**
   * Render the cell in column approver
   * @param {object} data
   * @returns {CellApprover} component
   */
  const cellApprover = (data) => {
    const { approver, approverType } = data.dataItem;
    return <CellApprover name={approver} type={approverType} />;
  };

  /**
   * Render the cell in column approver
   * @param {object} data
   * @returns {CellApprover} component
   */
  const cellAuthor = (data) => {
    const { modifiedByUser, modifiedByUserType } = data.dataItem;
    return <CellApprover name={modifiedByUser} type={modifiedByUserType} />;
  };

  /**
   * Render the cell in column date creation
   * @param {object} data
   * @returns {CellDetails} component
   */
  const cellTotal = (data) => {
    const { final_amount } = data.dataItem;
    return <CellTotal total={final_amount} />;
  };

  if (errorOrder) {
    return <Notification message={errorOrder} type="error" />;
  }

  return (
    <div className="u-bg-beige">
      <HeaderOrderListing />
      {loading || loadingOrder || loadingIndexWebCustomer ? (
        <Loader className="c-loader--center" size="large" />
      ) : orders?.length === 0 ? (
        <div className="c-empty-state-orders">
          <EmptySearch
            icon={pagerState.search ? "search" : null}
            strongText={
              pagerState.search
                ? labelStrongEmptySearch
                : labelStrongEmptyListOrder
            }
            text={pagerState.search ? labelEmptySearch : labelEmptyListOrder}
          />
        </div>
      ) : (
        <Grid
          className="c-orders-listing c-orders-listing--pending  c-orders-listing--pending-small"
          data={orders}
          pageable={{ buttonCount: 5, info: false, pageSizes }}
          onPageChange={pageChange}
          skip={(pagerState?.page - 1) * pagerState?.perPage}
          sortable={{
            allowUnsort: true,
            mode: "single",
          }}
          sort={[
            {
              field: pagerState.orderBy,
              dir: pagerState.orderByDirection,
            },
          ]}
          onSortChange={sortChange}
          take={pagination?.size}
          total={pagination?.total}
        >
          {layoutColumn &&
            layoutColumn.map((column) => {
              if (!column.hidden || column.propId === "id") {
                let cell = null;
                switch (column?.propId) {
                  case "code":
                    cell = cellIDOrder;
                    break;
                  case "customer_description":
                    cell = cellCustomerDescription;
                    break;
                  case "approverType":
                    cell = cellApprover;
                    break;
                  case "modifiedByUserType":
                    cell = cellAuthor;
                    break;
                  case "creation_date":
                    cell = cellDateCreation;
                    break;
                  case "delivery_date":
                    cell = cellDateDelivery;
                    break;
                  case "state":
                    cell = cellState;
                    break;
                  case "id":
                    cell = cellDetails;
                    break;
                  case "final_amount":
                    cell = cellTotal;
                    break;
                  default:
                    cell = null;
                    break;
                }

                return (
                  <Column
                    key={column?.propId}
                    field={column?.propId}
                    title={column?.propId === "id" ? " " : column?.label}
                    cell={cell}
                    locked={column?.propId === "id" ? true : false}
                  />
                );
              }
              return null;
            })}
        </Grid>
      )}
      {showModal && <ModalDocumentsOrder handleModal={handleModal} />}
    </div>
  );
};

export default OrdersProcessing;
