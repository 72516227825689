import React from "react";
import { useSelector } from "react-redux";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import CellCode from "./cellCode";
import CellPrice from "./cellPrice";
import CellPreviewGallery from "./cellPreviewGallery";
import CellDescription from "./cellDescription";

const Table = () => {
  const fragranceTableRows = useSelector(
    (state) => state.fragrance.fragranceTableRows
  );
  const layoutColumn = useSelector((state) => state.fragrance.layoutColumn);

  /**
   * Render the cell in column code
   * @param {object} data
   * @returns {CellCode} component
   */
  const cellCode = (data) => {
    const { article_code, available_from, tag_promo } = data.dataItem;
    return (
      <CellCode 
        article_code={article_code} 
        available_from={available_from} 
        tag_promo={tag_promo}
      />
    );
  };

  /**
   * Render the cell in column price
   * @param {object} data
   * @returns {CellPrice} component
   */
  const cellPrice = (data) => {
    const { price } = data.dataItem;
    return <CellPrice price={price} />;
  };

  /**
   * Render the cell in column price
   * @param {object} data
   * @returns {CellPrice} component
   */
    const cellPreviewGallery = (data) => {
      const { article_id, article_thumb } = data.dataItem;

      return (
        <CellPreviewGallery 
          article_id={article_id} 
          thumbnail={article_thumb} 
        />
      );
    };

  /**
   * Render the cell in column description
   * @param {object} data
   * @returns {CellPrice} component
   */
    const cellDescription = (data) => {
      const { full_description, is_out_of_stock, out_of_stock_message} = data.dataItem;
      return (
        <CellDescription
            full_description={full_description}
            is_out_of_stock={is_out_of_stock}
            out_of_stock_message={out_of_stock_message}
        />
      );
    };

  return (
    <section>
      <Grid className="c-good-grid" data={fragranceTableRows}>
        {layoutColumn &&
          layoutColumn.map((column) => {
            if (!column.hidden) {
              let cell = null;
              switch (column?.propId) {
                case "article_code":
                  cell = cellCode;
                  break;
                case "price":
                  cell = cellPrice;
                  break;
                case "article_thumb":
                  cell = cellPreviewGallery;
                  break;
                case "full_description":
                  cell = cellDescription;
                  break;
                default:
                  cell = null;
                  break;
              }
              return (
                <Column
                  key={column?.propId}
                  field={column?.propId}
                  title={column.propId === 'article_thumb' ? ' ' : column?.label}
                  cell={cell}
                  sortable={true}
                  locked={column?.propId === "article_code"}
                />
              );
            }
            return null;
          })}
      </Grid>
    </section>
  );
};

export default Table;
