import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPagerStateOrderPending } from "redux/features/order";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { DebounceInput } from "react-debounce-input";
import ResetFilter from "components/resetFilter";
import "./header.scss";
import { useCustomLocalization } from "utils";

import defaultMessages from "i18n/en.json";

const useLocalizedMessages = () => {
    const [toLanguageString] = useCustomLocalization();
    return {
        initialValuesDropdown: toLanguageString(
            "metislab.frontend.components.orders.components.header.initialValuesDropdown",
            defaultMessages.metislab.frontend.components.orders.components.header
                .initialValuesDropdown
        ),
        placeholderInputSearch: toLanguageString(
            "metislab.frontend.components.orders.components.header.placeholderInputSearch",
            defaultMessages.metislab.frontend.components.orders.components.header
                .placeholderInputSearch
        ),
        title: toLanguageString(
            "metislab.frontend.components.orders.components.header.titlePending",
            defaultMessages.metislab.frontend.components.orders.components.header
                .titlePending
        ),
    };
};

const HeaderOrderListing = () => {
    const { initialValuesDropdown, placeholderInputSearch, title } =
        useLocalizedMessages();

    const dispatch = useDispatch();

    const INITIAL_VALUES_DROPDOWN = {
        label: initialValuesDropdown,
        value: null,
    };

    /**
     * Order states
     */

    const orderPermissions = useSelector((state) => state.order.orderPermissions);
    const layoutColumn = useSelector((state) => state.order.layoutColumn);
    const pagerState = useSelector((state) => state.order.pagerStateOrderPending);

    /**
     * Internal states
     */
    const [keyword, setKeyword] = useState("");

    const dropDownListArray =
        layoutColumn &&
        layoutColumn.filter(
            (row) =>
                row.propId === "search_code" ||
                row.propId === "customer_description" ||
                row.propId === "code" ||
                row.propId === "modifiedByUserType" ||
                row.propId === "approverType"
        );

    useEffect(() => {
        if (pagerState?.search) {
            setKeyword(pagerState?.search);
        } else {
            setKeyword("");
        }
    }, [pagerState]);

    /**
     * onChangeType is used to handle the dropdown
     * @param {*} e
     */
    const onChangeType = (e) => {
        const value =
            e.value === INITIAL_VALUES_DROPDOWN
                ? null
                : { propId: e.value.propId, label: e.value.label };

        dispatch(
            setPagerStateOrderPending({
                searchBy: value,
            })
        );
    };

    /**
     * onChangeKeyword is used to handle the string in the input
     * @param {*} e
     */
    const onChangeKeyword = (e) => {
        setKeyword(e.target.value);
        dispatch(
            setPagerStateOrderPending({
                page: 1,
                search: e.target.value,
            })
        );
    };

    /**
     * handleFilter is used to reset the dropdown filter and the input field
     */
    const handleResetFilter = () => {
        dispatch(
            setPagerStateOrderPending({
                ...pagerState,
                search: null,
                searchBy: null,
            })
        );
        setKeyword("");
    };

    return (
        <>
            <header className="c-subheader c-subheader--pending-orders l-desktop-flex k-align-items-center k-justify-content-between">
                <h1 className="c-subheader__title t-title t-title--2 k-m-0">{title}</h1>
                <ul className="c-filter l-tablet-flex k-align-items-center">
                    {(pagerState?.search || pagerState?.searchBy) && (
                        <li>
                            <ResetFilter dispatchResetAction={handleResetFilter} />
                        </li>
                    )}
                    <li>
                        <DropDownList
                            className="c-filter-container"
                            name="searchByStatus"
                            data={dropDownListArray || []}
                            fillMode="outline"
                            textField="label"
                            dataItemKey="propId"
                            defaultItem={INITIAL_VALUES_DROPDOWN}
                            onChange={onChangeType}
                            value={pagerState.searchBy}
                            popupSettings={{ popupClass: "c-filter-content", width: 180 }}
                        />
                    </li>
                    <li className="c-form-field c-form-field--search">
                        <DebounceInput
                            className="k-input"
                            name="searchByKeyword"
                            placeholder={placeholderInputSearch}
                            minLength={0}
                            debounceTimeout={300}
                            value={keyword}
                            onChange={onChangeKeyword}
                        />
                        <span className="k-icon k-i-search"></span>
                    </li>
                </ul>
            </header>
            {!orderPermissions?.orderEdit &&
                orderPermissions?.orderDisabledMessage.length > 0 && (
                    <div
                        className="c-disabled-message"
                        dangerouslySetInnerHTML={{
                            __html: orderPermissions?.orderDisabledMessage,
                        }}
                    />
                )}
        </>
    );
};

export default HeaderOrderListing;
