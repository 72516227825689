import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "redux/features/user";
import { Button } from "@progress/kendo-react-buttons";
import { useCustomLocalization } from "utils";

import defaultMessages from "i18n/en.json";

const useLocalizedMessages = () => {
  const [toLanguageString] = useCustomLocalization();
  return {
    languageEnglish: toLanguageString(
      "metislab.frontend.components.user.components.section.language.languageEnglish",
      defaultMessages.metislab.frontend.components.user.components.section
        .language.languageEnglish
    ),
    languageItalian: toLanguageString(
      "metislab.frontend.components.user.components.section.language.languageItalian",
      defaultMessages.metislab.frontend.components.user.components.section
        .language.languageItalian
    ),
  };
};

const FooterUnauthenticated = () => {

  const dispatch = useDispatch();

  const {
    languageEnglish,
    languageItalian,
  } = useLocalizedMessages();

  const language = useSelector( (state) => state.user.language);

  const localLanguage = JSON.parse(localStorage.getItem('lang'))
  
  const handleClick = (lang) => {
    dispatch(setLanguage(lang))
  }

  return (
    <div className="c-footer-unaunthicated l-mobile-flex u-flex-center">
      <Button 
        fillMode="link"
        onClick={() => handleClick("it-IT")}
        style={{
          fontWeight: ( language === 'it-IT' ||  localLanguage === 'it-IT' )
            ? "bold"
            : "normal"
        }}
      >
        {languageItalian}
      </Button>
      <Button 
        fillMode="link"
        onClick={() => handleClick("en-US")}
        style={{
          fontWeight: ( language === 'en-US' || localLanguage === 'en-US')
            ? "bold"
            : "normal"
        }}
      >
        {languageEnglish}
      </Button>
    </div>
  );
};

export default FooterUnauthenticated;
